import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import media from 'styled-media-query'
import VisuallyHidden from '@reach/visually-hidden'
import { Form, useFormState } from 'react-final-form'
import createDecorator, { getFormInputs } from 'final-form-focus'
import _ from 'lodash'
import { useTrackRepeatables } from '../../services/diagnostics/hooks'
import { translation } from '../../services/translations'

import { useFormStatusState } from '../../services/formStatusContext'
import P from '../P'
import Field from '../Field'
import Button from '../Button'
import RepeatableLabel from './RepeatableLabel'
import RepeatableForm from './RepeatableForm'
import RepeatableItem from './RepeatableItem'
import RepeatableInformation from './RepeatableInformation'

const Fieldset = styled(Field)`
  border: 0;
  &:focus {
    outline: 0;
  }
`

const Container = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.inputBorder};
  background-color: ${props => props.disabled ? '#FAFAFA' : '#fff'};
`

const RepeatableList = styled.ul`
  list-style: none outside;
  margin: 0;
  padding: 0;
  &:last-child li:last-child {
    border-bottom: 0;
  }
  > li {
    border-bottom: ${props => `1px solid ${props.theme.colors.inputBorder}`};
  }
`

const EmptyState = styled.div`
  border-bottom: ${props => `1px solid ${props.theme.colors.inputBorder}`};
  display: flex;
  width: 100%;
  justify-content: center;
`

const EmptyDisabledState = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-left: 15px;
`

const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px;
  ${media.greaterThan('small')`
    padding: 30px;
  `}
`

const Message = styled.p`
  color: ${props => props.theme.colors.secondary};
  margin: 1em
`

const focusOnErrors = createDecorator()

/**
 * Repeatable
 */
const Repeatable = (props) => {
  const {
    id,
    fields,
    label,
    schema,
    editable,
    required,
    meta,
    repeatableLabelPlaceholder,
    repeatableLabel,
    repeatableInformation,
    repeatableSeparator,
    repeatableDefaultValues,
    sourceInitialValues,
    targets,
    targetLabelKey,
    disabled,
    disableEdit,
    disableDelete = false,
    actionLabels,
    maxLength,
    disableEditingOnSubmittedReports
  } = props

  const actionLabelsWithDefaults = {
    add: 'Add',
    cancel: 'Cancel',
    update: 'Update',
    ...actionLabels
  }

  const { push, value, update, remove, name } = fields
  const { submitFailed, error, submitError, dirtySinceLastSubmit } = meta
  const fieldError = error || (!dirtySinceLastSubmit && submitError)
  const { setRequiredRepeatable, requiredRepeatables, removeOpenRepeatable, removeOpenRepeatableByName, clearRequiredRepeatableId } = useFormStatusState()
  const [mode, setMode] = useState('VIEW')
  const { values: parentValues } = useFormState()

  const [_trackRepeatableState, trackRepeatables] = useTrackRepeatables({ constants: { label, id }, useFormState })
  useEffect(() => {
    if (required && !_.includes(requiredRepeatables, id)) {
      setRequiredRepeatable(id)
    }
    if (!required && _.includes(requiredRepeatables, id)) {
      clearRequiredRepeatableId(id)
    }
  }, [required, requiredRepeatables])

  const maxRepeatableLengthReached = maxLength && value && value.length === maxLength

  const onSubmit = (values) => {
    values = _.set(values, 'id', uuidv4())
    values = _.set(values, 'repeatableStatus', 'draft')
    values = _.omit(values, '_parentFormValues')
    const targetId = _.get(values, 'targetId')
    const targetName = _.get(values, 'targetName')
    if (targetId && !targetName) {
      const newTargetName = _.get(_.find(targets, target => target.id === targetId), targetLabelKey)
      values = _.set(values, 'targetName', newTargetName)
    }
    setMode('VIEW')
    push(values)
    trackRepeatables({ type: 'SUBMIT_INSTANCE', payload: { mode, instance: values } })
  }

  const onEditing = (editing) => {
    setMode(editing ? 'EDIT' : 'VIEW')
    if (editing) {
      trackRepeatables({ type: 'EDIT_INSTANCE', payload: { mode } })
    }
  }

  const removeItem = (index) => {
    remove(index)
    trackRepeatables({ type: 'REMOVE_INSTANCE', payload: { index, mode, repeatables: value } })
    removeOpenRepeatableByName(name)
  }

  const cancelAdd = (formId) => {
    setMode('VIEW')
    trackRepeatables({ type: 'CANCEL_INSTANCE', payload: { mode } })
    removeOpenRepeatable(formId)
  }

  const addNew = () => {
    setMode('ADD')
    trackRepeatables({ type: 'START_INSTANCE', payload: { mode } })
  }
  if (!editable) {
    return null
  }

  const renderRepeatables = () => {
    if (disabled && !_.size(value)) {
      return (
        <EmptyDisabledState>
          -
        </EmptyDisabledState>
      )
    }
    if (mode === 'VIEW' && !_.size(value)) {
      return (
        <EmptyState>
          <P>{translation(`Press 'Add' Below`)}</P>
        </EmptyState>
      )
    }

    return (
      <RepeatableList>
        {_.map(value, (item, index) => {
          return (
            <RepeatableItem
              initialValues={sourceInitialValues}
              key={index}
              values={item}
              index={index}
              parentValues={parentValues}
              schema={schema}
              onSubmit={(values) => update(index, _.omit(values, '_parentFormValues'))}
              onRemove={() => removeItem(index)}
              onEditing={onEditing}
              repeatableLabelPlaceholder={repeatableLabelPlaceholder}
              repeatableLabel={repeatableLabel}
              repeatableSeparator={repeatableSeparator}
              repeatableDefaultValues={repeatableDefaultValues}
              fieldName={name}
              label={label}
              required={required}
              mode={mode}
              disabled={disabled}
              disableEdit={disableEdit}
              disableDelete={disableDelete}
              actionLabels={actionLabelsWithDefaults}
              disableEditingOnSubmittedReports={disableEditingOnSubmittedReports}
            />
          )
        })}
      </RepeatableList>
    )
  }

  return (
    <Fieldset
      as={'fieldset'}
      tabIndex={fieldError ? '-1' : ''}
      data-name={name}
    >
      <VisuallyHidden>
        <legend>{label}</legend>
      </VisuallyHidden>
      <RepeatableLabel
        label={label}
        required={required}
        error={fieldError}
        submitFailed={submitFailed}
        disabled={disabled}
      />
      <RepeatableInformation
        content={repeatableInformation}
      />
      <Container disabled={disabled}>
        {renderRepeatables()}
        {mode === 'ADD' && (
          <Form
            component={RepeatableForm}
            decorators={[focusOnErrors]}
            parentValues={parentValues}
            onSubmit={onSubmit}
            onCancel={cancelAdd}
            schema={schema}
            nested={true}
            formName={_.camelCase(label)}
            fieldName={name}
            required={required}
            initialValues={sourceInitialValues}
            actionLabels={actionLabelsWithDefaults}
          />
        )}
        {mode === 'VIEW' && !disabled && !maxRepeatableLengthReached && (
          <Actions>
            <Button onClick={() => addNew()}>{translation(actionLabelsWithDefaults.add)}</Button>
          </Actions>
        )}
        {maxRepeatableLengthReached && (
          <Message>{translation('Max Repeatable Length - Message', { maxLength })}</Message>
        )}
      </Container>
    </Fieldset>
  )
}

Repeatable.propTypes = {
  fields: PropTypes.object,
  label: PropTypes.string.isRequired,
  schema: PropTypes.array,
  required: PropTypes.bool,
  meta: PropTypes.object
}

Repeatable.defaultProps = {
  required: false,
  fields: {},
  meta: {}
}

export default Repeatable

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import media from 'styled-media-query'
import { translation } from '../../services/translations'

import VisuallyHidden from '@reach/visually-hidden'
import Tooltip from '@reach/tooltip'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import RepeatableForm from './RepeatableForm'
import IconButton from '../IconButton'

const Container = styled.li`
  background: ${props => props.editable ? props.theme.colors.lightGray : 'none'};`

const Heading = styled.div`
  padding: 15px;
  padding-bottom: ${props => props.editable ? '0' : '15px'};
  display: flex;
  align-items: center;
`

const Summary = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: ${props => `${props.theme.type.label.small}px`};
  line-height: 1.25;
  flex: 1;
  ${media.greaterThan('small')`
    font-size: ${props => `${props.theme.type.label.large}px`};
    line-height: 1.4;
  `}
`

const Actions = styled.ul`
  list-style: outside none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  > li {
    margin-left: 10px;
  }
`

const IndexDisplay = styled.strong`
  margin: 0 5px 0 0;
`

const focusOnErrors = createDecorator()

/**
 * Displays a repeatable entry and editing form
*/
const RepeatableItem = (props) => {
  const {
    initialValues,
    values,
    index,
    onSubmit,
    onRemove,
    onEditing,
    parentValues,
    schema,
    repeatableLabelPlaceholder,
    repeatableLabel,
    repeatableSeparator,
    fieldName,
    mode,
    disabled,
    disableEdit,
    disableDelete,
    actionLabels,
    disableEditingOnSubmittedReports
  } = props
  const initialOpen = _.get(values, '_isOpen', false)
  const [isOpen, setIsOpen] = useState(initialOpen)
  const isAddMode = mode === 'ADD' 
  const isRepeatableDraft = _.get(values, 'repeatableStatus') === 'draft'
  const readOnly = disabled || disableEdit || (!isRepeatableDraft && disableEditingOnSubmittedReports)
  const targetName = _.get(values, 'targetName')
  const prepopulated = _.get(values, 'prepopulated', false)
  useEffect(() => {
    onEditing(isOpen)
  }, [isOpen])

  const handleSubmit = (values) => {
    const valuesWithoutMeta = _.omit(values, '_isOpen')
    setIsOpen(false)
    onSubmit(valuesWithoutMeta)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const generateDescription = () => {
    let description = `${repeatableLabelPlaceholder} ${index + 1}`
    let indexValue = null

    const descriptionWithValues = _.compact(_.map(_.castArray(repeatableLabel), (label) => {
      const schemaField = _.find(schema, { id: label })
      const fieldValue = _.get(values, label)
      if (fieldValue && schemaField) {
        let descriptionLabel = fieldValue

        if (schemaField.field === 'Dropdown') {
          const matchingOption = _.find(_.get(schemaField, 'props.options'), { value: fieldValue })
          if (matchingOption) {
            descriptionLabel = translation(_.get(matchingOption, 'label'))
          }
        }

        return descriptionLabel
      }
    }))

    if (_.size(descriptionWithValues)) {
      description = `${_.join(_.uniq(descriptionWithValues), repeatableSeparator)}`
      indexValue = index + 1
    }

    if (targetName) {
      description = targetName
    }

    return { description, indexValue }
  }

  const { description, indexValue } = generateDescription()
  const formName = _.camelCase(description)

  const headingLabel = readOnly ? 'Viewing' : 'Editing'
  const tooltipLabel = readOnly ? 'View' : 'Edit'
  const icon = 'edit'

  const renderSummary = () => {
    return (
      <Heading editable={isOpen}>
        <Summary>
          {indexValue && <IndexDisplay>{`${indexValue}.`}</IndexDisplay>}
          {(isOpen && !initialOpen) && <strong>{translation(headingLabel)}: </strong>} {description}
        </Summary>
        {!isOpen && <Actions>
          <li>
            <Tooltip
              label={isAddMode ? translation('Disabled while adding new entry') : translation(tooltipLabel)}
              aria-label={isAddMode ? translation('Disabled while adding new entry') : translation(tooltipLabel)}
            >
              <IconButton disabled={isAddMode} label={translation('Edit')} onClick={() => setIsOpen(true)} icon={icon} />
            </Tooltip>
          </li>
          {!prepopulated && !readOnly && (isRepeatableDraft || !disableEditingOnSubmittedReports) &&
          <li>
            <Tooltip
              label={isAddMode ? translation('Disabled while adding new entry') : translation('Delete')}
              aria-label={isAddMode ? translation('Disabled while adding new entry') : translation('Delete')}
            >
              <IconButton disabled={isAddMode} label={translation('Delete')} onClick={onRemove} icon={'remove'} />
            </Tooltip>
          </li>}
        </Actions>}
      </Heading>
    )
  }

  const renderForm = () => {
    if (!isOpen) {
      return null
    }

    return (
      <Form
        initialValues={{ ...initialValues, ...values }}
        component={RepeatableForm}
        decorators={[focusOnErrors]}
        onSubmit={handleSubmit}
        onClose={!initialOpen && onClose}
        parentValues={parentValues}
        schema={schema}
        formName={formName}
        fieldName={fieldName}
        disabled={readOnly}
        actionLabels={actionLabels}
      />
    )
  }

  return (
    <Container editable={readOnly}>
      {renderSummary()}
      {renderForm()}
    </Container>
  )
}

RepeatableItem.propTypes = {
  /**
   * Form field values
   */
  values: PropTypes.object.isRequired,
  /**
   * Position within the repeatable list
   */
  index: PropTypes.number.isRequired,
  /**
   * Callback when submitting the form
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Form values from the parent form
   */
  parentValues: PropTypes.object,
  /**
   * Schema from Vigilance Hub
   */
  schema: PropTypes.array.isRequired,
  /**
   * Generic label to use instead of 'Item'.
   *
   * For example 'Drug' would become 'Drug 1' instead of 'Item 1'
   */
  repeatableLabelPlaceholder: PropTypes.string,
  /**
   * Field names for the report to show within the placeholder.
   *
   * For example ['firstName', 'lastName'] would create 'Barry Lewis' if those values were in the form
   */
  repeatableLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  /**
   * The separator used when combing values from 'repeatableLabel'
   */
  repeatableSeparator: PropTypes.string
}

RepeatableItem.defaultProps = {
  repeatableSeparator: ' ',
  repeatableLabelPlaceholder: 'Item'
}

export default RepeatableItem
